

const hideShow = (function () {



	const init = () => { 

		const openTrigger = document.getElementById("projectInfoBtn");
		const projectText = document.getElementById("project-info");

		if(openTrigger != null ){

			openTrigger.addEventListener("click",function(e){
			  	projectText.classList.toggle("open");
			  	if (openTrigger.innerHTML === "Project Info") {
				    openTrigger.innerHTML = "Close"; 
				} else {
				    openTrigger.innerHTML = "Project Info"; 
				}
			});
		}
	};


	return{
		init: init
	}

})();

export { hideShow };
