import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import lazySizes from 'lazysizes';
import {
	scrollTo,
	stopScroll,
	resumeScroll,
} from './utilities/scrollControl';

import { sticky } from './modules/nav/sticky';

import { hideShow } from './modules/hideShow'; 
import { pageLoader } from './modules/pageLoader';
import { navTrigger } from './modules/nav/navTrigger';
import { googleMaps } from './modules/googleMaps.js'
import { labelSlide } from '@m/label-slide';
import glideInit from './modules/glideInit';
lazySizes.cfg.init = false; 
// VUE

// App main
const main = async () => {
	lazySizes.init(); 
	labelSlide.init(); 
	navTrigger.init();
	glideInit.init() 
	pageLoader.init();  
	hideShow.init(); 
	sticky.init();
	googleMaps.initMap() 
};


const swup = new Swup({

  animationSelector: '[class*="swup-transition-"]',

  plugins: [
  new SwupScrollPlugin({
  	doScrollingRightAway: false,
  	animateScroll: false,
  }),
  ],
});


main();

 
swup.on('contentReplaced', () => {
	main();
});
