

//
// scrollTo(htmlELEMENT, options )
// Available options
//
//  offset : NUMBER  |  scoll offset (for navs or spacing)
//
// safari / ios fix
//   import smoothscroll from 'smoothscroll-polyfill';
//  smoothscroll.polyfill();
//


function scrollTo(target, options) {
  let scrollPostion = target.getBoundingClientRect().top + window.pageYOffset;
  if(options.offset){
    scrollPostion += scrollPostion + yOffset;
  }
  window.scrollTo({top: scrollPostion, behavior: 'smooth'});
};



function scrollAnimate(eAmt, where) {
 if (where == "center" )
  window.scrollBy(0, eAmt / 2);
if (where == "top" || where == "")
  window.scrollBy(0, eAmt);
}



const stopScroll = () => {

 const scrollY = window.scrollY;
 const body = document.body
 const headerlogo = document.getElementById('header-logo'); 
 const html = document.querySelector('html');
 body.style.position = 'fixed';
 body.style.top = '-' + scrollY + 'px';
};

const resumeScroll = () => {
  const body = document.body;
 const html = document.querySelector('html');
 const scrollY = body.style.top; 
  // console.log(scrollY)
  body.style.position = '';
  body.style.top = '';
  html.style.marginLeft = '';
  window.scrollTo({top : parseInt( scrollY || '0') * -1});

};


export { scrollTo, stopScroll, resumeScroll };