// convert this to a class based function

import Glide from '@glidejs/glide';

// https://glidejs.com/docs/setup/

const glideInit = (() => {
	const glideInstance = {};

	const mobileCheck = () => {
		if (window.innerWidth <= 934) {
			return true;
		}
		return false;
	};
	const handleMobOnly = (instance) => {
		window.addEventListener('resize', () => {
			if (mobileCheck() && instance.disabled === true) {
				instance.enable();
				instance.mount();

				instance.on('resize', () => {
					if (mobileCheck() === false && instance.disabled === false) {
						// console.log('destroy');
						instance.disable();
						instance.destroy();
					}
				});
			}
		});
	};
	const initGlider = (el, glideOptions, options = { mobOnly: false }) => {
		/// check to see if an element exists
		const $el = Array.from(document.querySelectorAll(el));
		// if it does
		if ($el.length > 0) {
			//
			// create a promise to instantiate
			const instantiate = new Promise((resolve) => {
				glideInstance[el] = new Glide(el, glideOptions);

				// eslint-disable-next-line no-underscore-dangle
				if (glideInstance[el]._o.type.length > 0) {
					resolve(true);
				}
			});
			//
			instantiate.then(() => {
				// console.log(glideInstance);
				console.log(el);
				if (options.mobOnly !== true || (mobileCheck() === true && options.mobOnly == true)) {
					// console.log('mount');

					glideInstance[el].mount();
				}
				if (mobileCheck() === false && options.mobOnly === true) {
					glideInstance[el].disable();
					handleMobOnly(glideInstance[el]);
				}
			}).then(() => {
				if (options) {
					if (options.persistActive) {
						glideInstance[el].on('move', () => {
							const currentActive = document.querySelector('.glide__slide--active');
							currentActive.classList.add('still-active');
						});
					}
				}
			});
		}
	};

	const init = () => {
		initGlider('.glide--home', {
			type: 'carousel',
			perView: 1,
			gap: 0,
			autoplay: 3000,
		});
	};

	return {
		initGlider,
		init,

	};
})();

export default glideInit;
