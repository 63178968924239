

const pageLoader = (function () {



	const init = () => { 

		const addHide = document.getElementById("loading");

	    setTimeout(function() { 
	        document.getElementById("loading").classList.add("loaded") 
	    }, 2500);   
			

	};


	return{
		init: init
	}

})();

export { pageLoader };
