import {
  stopScroll,
  resumeScroll,
} from '../../utilities/scrollControl.js'



const navTrigger = (function(){

  let $trigger;
  let $triggerclose;
  let $nav;
  let $overlay;
  let $links;
  let $body;
  let $html;
  let state = 'closed';
  let animating = false; 

  const init = () =>{
    _bindElements();
    if ( $nav !== undefined &&  $nav !== null){
      $trigger.addEventListener('click', toggleMenu );
      $triggerclose.addEventListener('click', toggleMenu );
      $overlay.addEventListener('click', toggleMenu );
      $links.forEach( $link => {
        $link.addEventListener('click', toggleMenu );
      }); 
   }
  }
  const _bindElements = () =>{
    $nav = document.getElementById('main-nav');
    if ( $nav !== undefined &&  $nav !== null){
    $links = Array.from(document.querySelector('.main-nav__bar').querySelectorAll('a'));
    $body = document.body;
    $trigger = document.getElementById('main-nav-trigger');
    $html= document.getElementById('web');
    $html.style.overflowY = "scroll";
    $triggerclose = document.getElementById('main-nav-close');
    $overlay = document.getElementById('menuTint');
    } else {
    
      console.warn("Cant find Nav")

    }
  }
  const toggleMenu = () =>{
    if ( state == 'closed'){
      _handleOpen();
    } else{
      _handleClose();
    }   
  };
  const _handleOpen = ()=>{
    _handleAnimation();
    setTimeout( ()=>{
       $body.classList.add('nav-open');
      $nav.classList.add('is-open');
      $trigger.setAttribute('aria-expanded', 'true');
      $trigger.classList.add('is-active');

      stopScroll(); 
      state = 'open'; 
    }, 50);
  }
  const _handleClose = () =>{
    _handleAnimation();
    $body.classList.remove('nav-open'); 
    $nav.classList.remove('is-open');
    $triggerclose.setAttribute('aria-expanded', 'false');
    $triggerclose.classList.remove('is-active');

    resumeScroll();
    state = 'closed';
  }
  const _handleAnimation = () =>{
    $nav.classList.add('is-animating');
    setTimeout( ()=>{
      $nav.classList.remove('is-animating');
    }, 500);
  }
  return {
    init: init
  }
})();

export { navTrigger };