

const sticky = (function () {



	const init = () => { 

		const body = document.getElementById('swup');
		const scrollUp = "nav-sticky--show";
		const scrollDown = "nav-sticky--hide";
		let lastScroll = 0;  

		window.addEventListener("scroll", () => {
		  const currentScroll = window.pageYOffset;
		  
		  if (currentScroll <= 300) {
		    body.classList.remove(scrollUp); 
		    return;
		  }
		  
		  if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
		    // down
		    body.classList.remove(scrollUp);
		    body.classList.add(scrollDown);
		  } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) {
		    // up
		    body.classList.remove(scrollDown);
		    body.classList.add(scrollUp);
		  }
		  lastScroll = currentScroll;
		});
	};


	return{
		init: init
	}

})();

export { sticky };
